import algosdk from 'algosdk';
import { peraWallet } from "../components/PeraWalletButton";

export async function signTrx(wallet, claimPhrase) {
    try {
        const headers = new Headers()
        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')

        const res = await fetch('https://4yuk0yvcqj.execute-api.eu-west-1.amazonaws.com/v1/api/unmask', {
            method: 'POST',
            body: JSON.stringify({ wallet, claimPhrase }),
            headers
        })
        const body = await res.json()
        const optInTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject(body.params);
        const trx = [{txn: optInTxn, signers: [wallet]}]

        let [signed_optin] = await peraWallet.signTransaction([ trx ]);
        const sign_trx = Buffer.from(signed_optin).toString("base64");
        await fetch('https://4yuk0yvcqj.execute-api.eu-west-1.amazonaws.com/v1/api/submit', {
            method: 'POST',
            body: JSON.stringify({ signed_optin: sign_trx, claimPhrase, wallet }),
            headers
        })
      } catch (error) {
        console.error("Couldn't sign Opt-in txns", error);
      }
}