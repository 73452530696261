import PeraWalletButton from './components/PeraWalletButton'
import { useEffect, useState } from 'react';
import './App.css';
import { useStore } from '@nanostores/react';
import { claimString } from './utils/store'


function App() { 
  const [showButtons, setShowButtons] = useState(false)
  const claimStore = useStore(claimString)
  useEffect(() => {
    window.addEventListener('showbuttons', () => setShowButtons(true))
    try {
      claimString.set(new URLSearchParams(window.location.search).get('claim-phrase').split('-').join(' '))
    } catch {
      
    }
  }, [])
  return(
      <div className="App">
        <div className='Buttons'>
          {showButtons && claimStore && (<PeraWalletButton />)}
          {showButtons && <button className={claimStore ? "outline" : ""} onClick={()=>{window.open("https://accenture.pagetiger.com/mente-materia-energia-larte-della-creazione/1", '_blank');}}>RIVIVI LA TUA ESPERIENZA </button>}
        </div>
     </div>
  );
}

export default App;
